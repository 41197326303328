<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>

            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách thử thách</h6>
                  <router-link :to="'/campaign/challenge/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                    </b-button>
                  </router-link>
                </div>
                <b-form @submit.prevent="search()" >
                  <div class="filter-transaction">
                  <div class="row mb-6">
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Tên thử thách:</label>
                      <input
                          type="text"
                          placeholder="Nhập tên thử thách"
                          class="form-control datatable-input"
                          v-model="paramFilter.title"
                      />
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Danh mục:</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.categories_id"
                      >
                        <option value="">Tất cả</option>
                        <option v-for="(item, index) in categories" :key="index" :value="item.id">{{ item.name }}</option>
                      </select>
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Trạng thái:</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.status"
                      >
                        <option value="">Tất cả</option>
                        <option v-for="(item, index) in arrayFilter" :key="index" :value="item.status">{{ item.label }}</option>
                      </select>
                    </div>


                  </div>
                  <div class="row mb-6">
                    <div class="col-lg-5 mb-lg-0 mb-6">
                      <label>Thời gian hiển thị:</label>
                      <div class="row">
                        <div class="col-lg-6 mb-lg-0 mb-6">
                          <date-picker
                              id="d4"
                              name="dp4"
                              v-model="paramFilter.publish_start_at"
                              type="date"
                              placeholder="Từ ngày"
                              format="YYYY-MM-DD "
                              value-type="format"
                          ></date-picker>
                        </div>
                        <div class="col-lg-6 mb-lg-0 mb-6">
                          <date-picker
                              id="d4"
                              name="dp4"
                              v-model="paramFilter.publish_end_at"
                              type="date"
                              placeholder="đến ngày"
                              format="YYYY-MM-DD "
                              value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 mb-lg-0 mb-6">
                      <label>Thời gian diễn ra:</label>
                      <div class="row">
                        <div class="col-lg-6 mb-lg-0 mb-6">
                          <date-picker
                              id="d4"
                              name="dp4"
                              v-model="paramFilter.date_start"
                              type="date"
                              placeholder="Từ ngày"
                              format="YYYY-MM-DD "
                              value-type="format"
                          ></date-picker>
                        </div>
                        <div class="col-lg-6 mb-lg-0 mb-6">
                          <date-picker
                              id="d4"
                              name="dp4"
                              v-model="paramFilter.date_end"
                              type="date"
                              placeholder="đến ngày"
                              format="YYYY-MM-DD "
                              value-type="format"
                          ></date-picker>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                      </button>
                    </div>
                  </div>

                </div>
                </b-form>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(icon)="data">
                  <div>
                    <img width="50px" height="50px" :src="data.item.icon" alt="">

                  </div>
                </template>
                <template #cell(categories)="data">
                  <div>
                    <div v-if="data.item.categories">{{data.item.categories.name}}</div>
                    <div v-else>-</div>

                  </div>
                </template>

                <template #cell(date_display)="data">
                  <div>
                    {{formatDate(data.item.publish_start_at)}} - <br>
                    {{formatDate(data.item.publish_end_at)}}

                  </div>
                </template>
                <template #cell(date_join)="data">
                  <div>
                    {{formatDate(data.item.date_start)}} - <br>
                    {{formatDate(data.item.date_end)}}
                  </div>
                </template>
                <template #cell(status)="data">
                  <div class="label label-inline" :class="getStatus(data.item).class">
                    {{getStatus(data.item).label}}
                  </div>
                </template>
                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                      :to="'/campaign/challenge/edit/' + data.item.id"
                    >
                      <b-button pill variant="info" size="sm">Sửa </b-button>
                    </router-link>


                    <b-button
                      @click="destroy(data.item.id)"
                      pill
                      variant="danger"
                      size="sm"
                      >Xóa
                    </b-button>
                    <router-link
                        :to="'/campaign/challenge/clone/' + data.item.id"
                    >
                      <b-button pill variant="primary" size="sm">Clone</b-button>
                    </router-link>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  :total-rows="paginate.total"
                  :per-page="paginate.limit"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination>

              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

const CampaignRepository = RepositoryFactory.get("Campaign");

export default {
  name: "List.vue",
  components:{
    DatePicker
  },
  mixins: [Common],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "id",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "icon",
          label: "Icon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Name",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "categories",
          label: "Danh mục",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_display",
          label: "Thời gian hiển thị",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_join",
          label: "Thời gian diễn ra",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paramFilter:{
        title:'',
        categories_id:'',
        status:'',
        date_start:'',
        date_end:'',
        publish_end_at:'',
        publish_start_at:'',
        page:'',
      },
      arrayFilter:[],
      categories:[],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý thử thách", route: "/campaign/challenge" },
    ]);
    this.filter = this.$route.query;
    //this.getList();
  },
  methods: {
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      //this.paramFilter.page = this.currentPage;

      this.getList();
    },
    formatDate(value){
      return moment(String(value)).format("DD/MM/YYYY");
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await CampaignRepository.getListChallenge(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data.list;
          this.items = list.data;
          this.arrayFilter = body.data.array_filter;
          this.categories = body.data.categories;
          //this.currentPage = body.data.current_page;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa thử thách")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await CampaignRepository.deleteChallenge(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
    getStatus(item){
      var label = '';
      var cl = 'label-light-primary';
      var now = Date.now();
      var date_start = Date.parse(item.date_start);
      var date_end = Date.parse(item.date_end);
      if (date_start > now && date_end  >now){
         label = "Chưa diễn ra"
      }
      if (date_start < now && date_end > now){
        label = "Đang diễn ra"
        cl='label-light-warning'
      }
      if ( date_end < now){
        label = "Đã kết thúc"
        cl= 'label-light-success'
      }
      return {
        'label':label,
        'class':cl,
      }

    }
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getList();
  }
};
</script>

<style scoped></style>
